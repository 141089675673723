<template>
  <div>
    <el-table
      :data="tableList"
      class="table_border"
      :cell-style="cellStyle"
      v-loading="isLoading">
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="100">
      </el-table-column>
      <el-table-column
        label="任务名称"
        show-overflow-tooltip
        prop="title">
      </el-table-column>
      <el-table-column
        label="任务发布方"
        show-overflow-tooltip
        prop="publisherName">
      </el-table-column>
      <el-table-column
        label="任务接受人"
        show-overflow-tooltip
        prop="receiverName">
      </el-table-column>
      <el-table-column
        label="申诉内容"
        show-overflow-tooltip
        prop="appealContent">
      </el-table-column>
      <el-table-column
        label="提交时间"
        show-overflow-tooltip
        width="170"
        prop="updatedAt">
      </el-table-column>
      <el-table-column
        label="状态"
        width="100">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            :type="
            {
              1: 'success',
              2: 'info'
            }[scope.row.status]">
            {{
              {
                1: '已处理',
                2: '已过期'
              }[scope.row.status]
            }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      @paginationChange="paginationChange"
      :pageInfo="pageInfo">
    </pagination>
  </div>
</template>

<script>
import Pagination from '@/components/pagination/index.vue'
import api from '@/api/index'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isLoading: false,
      tableList: []
    }
  },
  created () {
    this.getList()
  },
  methods: {
    cellStyle () {
      return 'height: 48px; padding: 0px;'
    },
    paginationChange (newNum) {
      this.pageInfo.pageNum = newNum
      this.getList()
    },
    // 获取申诉列表
    getList () {
      this.isLoading = true
      api.adminAppealList({
        ...this.pageInfo,
        status: '1,2'
      }).then(res => {
        if (res.data.code === 0) {
          const { total, list, currentPage } = res.data.data
          this.tableList = list
          this.pageInfo.total = total
          this.pageInfo.pageNum = currentPage
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.isLoading = false
      })
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.table_border {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
