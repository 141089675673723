<template>
  <div class="complains_container">

    <div class="task_hall_title">
      <div class="logo">
        <img src="@/assets/images/complaints.png" alt="申诉处理">
      </div>
      <div style="margin-left: 10px;">申诉处理</div>
    </div>

    <!-- 申诉处理表格 -->
    <el-tabs
      v-model="activeName"
      class="task_hall_table"
      style="margin-top: 10px;">
      <el-tab-pane
        label="待处理"
        name="first">
        <pending-table></pending-table>
      </el-tab-pane>
      <el-tab-pane
        label="已处理"
        name="second">
        <handled-table></handled-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import HandledTable from './components/handled-table.vue'
import PendingTable from './components/pending-table.vue'

export default {
  components: {
    PendingTable,
    HandledTable
  },
  data () {
    return {
      activeName: 'first',
      pendingList: []
    }
  }
}
</script>

<style lang="scss" scoped>
.complains_container {
  height: 100%;
  padding: 0 30px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_hall_title {
    height: 60px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
}
</style>
